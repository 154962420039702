import React, { forwardRef } from 'react';
import styled from 'styled-components';

import {
  color,
  space,
  layout,
  compose,
  flexbox,
  variant,
  fontSize,
  textAlign,
  fontStyle,
  ColorProps,
  lineHeight,
  SpaceProps,
  fontFamily,
  fontWeight,
  LayoutProps,
  FlexboxProps,
  FontSizeProps,
  letterSpacing,
  verticalAlign,
  FontStyleProps,
  TextAlignProps,
  FontWeightProps,
  FontFamilyProps,
  LineHeightProps,
  ResponsiveValue,
  VerticalAlignProps,
} from 'styled-system';

interface BaseProps {
  as?: React.ElementType;
  scale?: string;
}

interface BoxKnownProps extends BaseProps, LayoutProps, ColorProps, SpaceProps {
  variant?: ResponsiveValue<string>;
}

export interface BoxProps
  extends BoxKnownProps,
    Omit<React.HTMLProps<HTMLDivElement>, keyof BoxKnownProps> {}

export interface ImageProps
  extends BoxKnownProps,
    Omit<React.HTMLProps<HTMLImageElement>, keyof BoxKnownProps> {}

export interface TextProps
  extends FontFamilyProps,
    FontSizeProps,
    FontStyleProps,
    TextAlignProps,
    FontWeightProps,
    LineHeightProps,
    FontWeightProps,
    VerticalAlignProps {}

const v = (props: { scale?: string; [key: string]: any }) =>
  variant({ scale: props.scale ?? 'variants' });

export const Box = styled('div')<BoxProps>(v, compose(layout, color, space));

export const Flex = styled(Box)<FlexboxProps>(flexbox);

Flex.defaultProps = {
  display: 'flex',
};

export const Image = forwardRef<HTMLImageElement, ImageProps>((props, ref) => {
  return <Box as="img" ref={ref} height="auto" maxWidth="100%" {...props} />;
});

export const Text = styled(Box)<TextProps>(
  compose(
    fontSize,
    textAlign,
    fontStyle,
    fontFamily,
    fontWeight,
    lineHeight,
    letterSpacing,
    verticalAlign
  )
);

export const Button = styled(Text)({});

Button.defaultProps = {
  as: 'button',
  scale: 'buttons',
  variant: 'primary',
};
