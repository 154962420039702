import React from 'react';

import Spinner from './Spinner';
import { Box, Flex } from 'components/styled';

const Loader = () => {
  return (
    <Box width="100%" height="100vh">
      <Flex width="100%" height="100%" flex="0 0 auto" alignItems="center" justifyContent="center">
        <Spinner size={60} />
      </Flex>
    </Box>
  );
};

export default Loader;
