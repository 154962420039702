const colors = {
  white: '#fff',
  black: '#000',
  danger: 'red',
  primary: 'blue',
  alto: '#d6d6d6',
  gray: '#898989',
  silver: '#cccccc',
  emperor: '#535353',
  tundora: '#404040',
  arapawa: '#0d2178',
  mariner: '#2064d9',
  lochmara: '#0073be',
  scorpion: '#5d5d5d',
  aliceBlue: '#f3faff',
  alabaster: '#f8f8f8',
  dustyGray: '#979797',
  silverDark: '#bbbbbb',
  outerSpace: '#323639',
  ribbonBlue: '#0035ff',
  blueRibbon: '#006efe',
  catalinaBlue: '#03186B',
  azureRadiance: '#0089ff',
  silverChalice: '#ababab',
  dodgerBlue: '#3399FF',
};

export default colors;
