import React from 'react';

interface Props {
  fallback?: React.ReactNode;
}

interface State {
  error: boolean;
}

export default class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      error: false,
    };
  }

  componentDidCatch(error: Error, info: React.ErrorInfo) {
    console.error('error', error, info);

    this.setState({ error: true });
  }

  render() {
    const { fallback, children } = this.props;

    return this.state.error ? fallback ?? <h1>Something went wrong</h1> : children;
  }
}
