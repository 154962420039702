import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import useContextMaybe from 'hooks/useContextMaybe';
import AuthService from 'services/AuthService';
import { LocalStorageKeys } from 'consts/localStorage';
import {
  addErrorInterceptor,
  addOauthInterceptor,
  removeErrorInterceptor,
  removeOauthInterceptor,
} from 'services/ApiService';
import ProfileService from 'services/ProfileService';

interface Context {
  token?: string;
  setToken: Dispatch<SetStateAction<any>>;
  user: any;
  setUser: Dispatch<SetStateAction<any>>;
  initialLoading: boolean;
  setInitialLoading: Dispatch<SetStateAction<any>>;
  logout: () => void;
}

const AuthContext = createContext<Context | undefined>(undefined);

/**
 * This is an example context that should serve as a reference for
 * any future context introducing recommended practises, like:
 * - always memoizing the context value,
 * - avoiding export of the actual context,
 * - recommended context typing,
 * - custom context consumer hooks
 */
export const AuthProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const [token, setToken] = useState(undefined);
  const [initialLoading, setInitialLoading] = useState(true);
  const [user, setUser] = useState(null);

  const logout = useCallback(() => {
    setToken(undefined);
    setUser(null);
    localStorage.removeItem(LocalStorageKeys.authToken);
  }, []);

  useEffect(() => {
    if (initialLoading) {
      return;
    }
    if (token) {
      addErrorInterceptor(logout);
    } else {
      removeErrorInterceptor();
      removeOauthInterceptor();
    }
  }, [token, initialLoading]);

  const value = useMemo(
    () => ({
      token,
      setToken,
      initialLoading,
      setInitialLoading,
      logout,
      user,
      setUser,
    }),
    [token, initialLoading, user, logout]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const {
    initialLoading,
    setInitialLoading,
    token,
    setToken,
    setUser,
    user,
    logout,
  } = useContextMaybe(AuthContext);

  const login = useCallback(async (email: string, password: string) => {
    try {
      const res = await AuthService.login(email, password);
      const t = res.data?.token;
      const user = res.data?.user;
      if (t) {
        setToken(t);
        setUser(user);
        localStorage.setItem(LocalStorageKeys.authToken, t);
        addOauthInterceptor(t);
      }
      return res;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }, []);

  const authenticate = useCallback(async () => {
    const authToken = localStorage.getItem(LocalStorageKeys.authToken);
    if (authToken) {
      addOauthInterceptor(authToken);
      try {
        const res = await ProfileService.getMyProfile();
        setToken(authToken);
        setUser(res?.data?.profile);
        if (initialLoading) {
          setInitialLoading(false);
        }
      } catch (error) {
        if (initialLoading) {
          setInitialLoading(false);
        }
      }
    } else {
      if (initialLoading) {
        setInitialLoading(false);
      }
    }
  }, [initialLoading]);

  return { token, initialLoading, login, authenticate, logout, user };
};
