import styled from 'styled-components';

import { spin } from 'styles/animations';

interface Props {
  size?: number;
}

const Spinner = styled('div')<Props>`
  border-radius: 50%;
  border: 1px solid #aaa;
  border-top-color: blue;
  width: ${({ size }) => size ?? 30}px;
  height: ${({ size }) => size ?? 30}px;
  animation: ${spin} 0.7s ease-in-out infinite;
`;

export default Spinner;
