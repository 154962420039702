import axios, { AxiosRequestConfig } from 'axios';

import { combineConfig, combineApiUrl } from 'utils/axios';

let errorInterceptor = -1;
let oauthInterceptor = -1;

export const addErrorInterceptor = (callback: () => void) => {
  if (errorInterceptor > -1) {
    return;
  }
  errorInterceptor = axios.interceptors.response.use(
    response => response,
    error => {
      if (error?.response?.status === 401) {
        callback();
      }
      return Promise.reject(error);
    }
  );
};

export const removeErrorInterceptor = () => {
  axios.interceptors.response.eject(errorInterceptor);
  errorInterceptor = -1;
};

export const addOauthInterceptor = (token: string) => {
  if (oauthInterceptor > -1) {
    return;
  }
  oauthInterceptor = axios.interceptors.request.use(config => {
    config.headers = { ...(config.headers ?? {}), Authorization: `Bearer ${token}` };

    return config;
  });
};

export const removeOauthInterceptor = () => {
  axios.interceptors.request.eject(oauthInterceptor);
  oauthInterceptor = -1;
};

const ApiService = {
  get: <R>(url: string, config?: AxiosRequestConfig) =>
    axios.get<R>(combineApiUrl(url), combineConfig(config)),

  post: <R, D = unknown>(url: string, data?: D, config?: AxiosRequestConfig) =>
    axios.post<R>(combineApiUrl(url), data, combineConfig(config)),

  put: <R, D = unknown>(url: string, data?: D, config?: AxiosRequestConfig) =>
    axios.put<R>(combineApiUrl(url), data, combineConfig(config)),

  delete: <R, D = unknown>(url: string, data?: D, config?: AxiosRequestConfig) =>
    axios.delete<R>(combineApiUrl(url), { data, ...combineConfig(config) }),
};

export default ApiService;
