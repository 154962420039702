import colors from './colors';
import { Styles } from 'types/styled';

const preset = {
  border: 'none',
  outline: 'none',
  cursor: 'pointer',
  backgroundColor: colors.primary,
};

const buttons: Styles = {
  primary: {
    ...preset,
    color: colors.white,
    borderRadius: '5px',
    padding: '10px 20px',

    ':disabled': {
      cursor: 'not-allowed',
      backgroundColor: colors.azureRadiance,
      opacity: '0.5',
    },
  },
  secondary: {
    ...preset,
    backgroundColor: colors.white,
    color: colors.white,
    background: colors.azureRadiance,
    borderRadius: '8px',
    padding: '10px 25px',
    fontSize: '1.2rem',
    textTransform: 'uppercase',

    ':disabled': {
      cursor: 'not-allowed',
      backgroundColor: colors.azureRadiance,
      opacity: '0.5',
    },
  },
  danger: {
    ...preset,
    color: colors.white,
    backgroundColor: 'red',
    borderRadius: '8px',
    padding: '10px 25px',
    fontSize: '1.2rem',
  },
  bigBlue: {
    ...preset,
    backgroundColor: colors.mariner,
    borderRadius: '8px',
    padding: '15px 50px',
    fontSize: '2.3rem',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    marginTop: '25px',
    marginBottom: '25px',
    color: colors.white,
    // ':hover': {
    //   backgroundColor: 'rgba(32, 100, 217, 0.8)',
    // },
    ':disabled': {
      backgroundColor: 'gray',
    },
  },
};

export default buttons;
