import colors from './colors';
import buttons from './buttons';
import variants from './variants';
import { Theme } from 'types/styled';

const theme: Theme = {
  colors,
  buttons,
  variants,
  space: [0, 8, 16, 24, 32, 48, 64, 72],
  fontSizes: [
    '1.2rem',
    '1.3rem',
    '1.4rem',
    '1.6rem',
    '1.8rem',
    '2rem',
    '2.2rem',
    '2.4rem',
    '3.2rem',
  ],
  fontWeights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
  breakpoints: ['480px', '769px', '1025px', '1201px'],
};

export default theme;
