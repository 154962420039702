import ApiService from './ApiService';

const url = '/insurant';

const AuthService = {
  postCode: (code: any, hash: any) => ApiService.post<any>(`${url}/login`, { code, hash }),
  requestCode: (hash: any) => ApiService.get<any>(`${url}/request/code/${hash}`),
  getCode: (hash: any) => ApiService.get<any>(`${url}/send/code/${hash}`),
  forgotPassword: (email: string) => ApiService.post<any>('/forgot-password', { email }),
  resetPassword: (email: string, token: string, password: string, passwordConfirmation: string) =>
    ApiService.post<any>('/reset-password', {
      email,
      token,
      password,
      password_confirmation: passwordConfirmation,
    }),
  login: (email: string, password: string) => ApiService.post<any>('/login', { email, password }),
  register: (
    honorificId: number,
    firstName: string,
    lastName: string,
    email: string,
    password: string
  ) =>
    ApiService.post<any>('/register', {
      honorific_id: honorificId,
      first_name: firstName,
      last_name: lastName,
      email,
      password,
    }),
  verifyRegistration: (email: string, hash: string) =>
    ApiService.post('/verifyEmail', {
      email,
      hash,
    }),
};

export default AuthService;
