import React from 'react';

import { Box, BoxProps } from 'components/styled';

const Container = ({ children, ...props }: React.PropsWithChildren<BoxProps>) => {
  return (
    <Box px={[4, 4, 7]} {...props}>
      {children}
    </Box>
  );
};

export default Container;
