type buildType = 'broker' | 'insurant' | 'admin';

const Config = {
  ApiUrl: `${process.env.REACT_APP_API_URL}/api`,
  WebSocketsUrl: `${process.env.REACT_APP_WS_URL}`,
  ApiKey: `${process.env.REACT_APP_API_KEY}`,
  BuildEnv: process.env.REACT_APP_BUILD_ENV as buildType,
};

export default Config;
