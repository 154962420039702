import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  *, ::before, ::after {
    box-sizing: border-box;
  }
  
  html {
    font-size: 10px;
  }

  body {
    max-width: 100%;
    height: 100vh;
    font-size: 1.4rem;
    font-weight: normal;
    font-family: 'Roboto', sans-serif;
    background-color: #F8F8F8;
  }

  #root {
    width: 100%;
    height: 100%;
  }
`;

export default GlobalStyles;
