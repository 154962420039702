import React from 'react';
import { Helmet } from 'react-helmet-async';

import Container from 'components/Container';
import { Box, Image, Text } from 'components/styled';

const NotFound = () => {
  return (
    <>
      <Helmet>
        <title>404 - Not Found</title>
      </Helmet>
      <Container>
        <Box mx="auto" py="200px">
          <Image
            mx="auto"
            width="100%"
            display="block"
            alt="Not found"
            maxWidth="600px"
            src="images/404.svg"
          />
          <Text as="h2" textAlign="center" mt="50px">
            We can't find what you're looking for
          </Text>
        </Box>
      </Container>
    </>
  );
};

export default NotFound;
