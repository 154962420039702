import Config from 'consts/config';

interface Route {
  path: string;
  exact?: boolean;
  component: string;
}

const publicRoutes: Route[] = [
  {
    path: '/login',
    exact: true,
    component: 'Auth/Login/LoginBrokerPage',
  },
  {
    path: '/reset-password/:token',
    exact: true,
    component: 'Auth/ResetPassword/ResetPasswordPage',
  },
  {
    path: '/register',
    exact: true,
    component: 'Auth/Register/RegisterPage',
  },
  {
    path: '/verify',
    exact: true,
    component: 'Auth/Register/VerifyRegistrationPage',
  },
];

const brokerRoutes: Route[] = [
  {
    path: '/dashboard',
    exact: true,
    component: 'Dashboard/DashboardPage',
  },
  {
    path: '/meine-vorgaenge',
    exact: true,
    component: 'Transactions/TransactionsPage',
  },
  {
    path: '/meine-vorgaenge/vorgang/:id',
    component: 'Transactions/offer/TransactionOfferPage',
  },
  {
    path: '/neuer-vorgang',
    exact: true,
    component: 'Transactions/new/TransactionNewPage',
  },
  {
    path: '/einstellungen',
    exact: true,
    component: 'Profile/ProfilePage',
  },
];

const insurantRoutes: Route[] = [
  {
    path: '/angebot',
    exact: true,
    component: 'Auth/Login/LoginOfferPage',
  },
  {
    path: '/angebot/view',
    exact: true,
    component: 'Offers/OfferPresentation',
  },
  {
    path: '/angebot/accept',
    exact: true,
    component: 'Offers/AcceptOffer',
  },
  {
    path: '/data-protection',
    exact: true,
    component: 'DataProtection/DataProtectionPage',
  },
];

const adminRoutes: Route[] = [
  {
    path: '/',
    exact: true,
    component: 'Admin/GlobalStats/GlobalStatsPage',
  },
];

const env = Config.BuildEnv;

export const getRoutes = (isLoggedIn: boolean) => {
  if (env === 'admin') {
    return adminRoutes;
  }
  if (env === 'insurant') {
    return insurantRoutes;
  }

  if (isLoggedIn) {
    return brokerRoutes;
  }

  return publicRoutes;
};

export const getRedirect = (isLoggedIn: boolean) => {
  if (env === 'admin') {
    return '/';
  }
  if (env === 'insurant') {
    return '/angebot';
  }

  if (isLoggedIn) {
    return '/meine-vorgaenge';
  }

  return '/login';
};
