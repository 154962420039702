import React, { Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { getRedirect, getRoutes } from 'config/routes';
import NotFound from 'pages/NotFound';
import Loader from 'components/Loader';
import ErrorBoundary from 'components/ErrorBoundary';
import InternalServerError from 'pages/InternalServerError';
import { useAuth } from 'contexts/AuthContext';
import { useMount } from 'react-use';
import Config from 'consts/config';

const Routes = () => {
  const { token, initialLoading, authenticate } = useAuth();

  useMount(() => {
    if (Config.BuildEnv === 'broker') {
      authenticate();
    }
  });

  if (Config.BuildEnv === 'broker' && initialLoading) {
    return <div />;
  }

  const routes = getRoutes(!!token);
  const redirect = getRedirect(!!token);

  return (
    <ErrorBoundary fallback={<InternalServerError />}>
      <Suspense fallback={<Loader />}>
        <Switch>
          {routes.map(({ path, exact, component }) => (
            <Route
              key={path}
              path={path}
              exact={exact}
              component={lazy(() => import(`pages/${component}`))}
            />
          ))}
          <Redirect exact from={token ? '/' : '*'} to={redirect} />
          <Redirect exact from={token ? '/login' : '*'} to={redirect} />
          <Route path="*" component={NotFound} />
        </Switch>
      </Suspense>
    </ErrorBoundary>
  );
};

export default Routes;
