import React from 'react';
import { ThemeProvider } from 'styled-components';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import de from 'date-fns/locale/de';

import Routes from './Routes';
import GlobalStyles from './GlobalStyles';

import theme from 'styles/theme/default';
import { AuthProvider } from 'contexts/AuthContext';

registerLocale('de', de);
setDefaultLocale('de');

const App = () => {
  return (
    <HelmetProvider>
      <Helmet defaultTitle="Sendrify" titleTemplate="Sendrify | %s" />
      <Router>
        <AuthProvider>
          <ThemeProvider theme={theme}>
            <Routes />
          </ThemeProvider>
        </AuthProvider>
      </Router>
      <ToastContainer />
      <GlobalStyles />
    </HelmetProvider>
  );
};

export default App;
