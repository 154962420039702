import { AxiosRequestConfig } from 'axios';

import Config from 'consts/config';

export const defaultConfig: AxiosRequestConfig = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Api-key': Config.ApiKey,
  },
};

export const combineConfig = (config?: AxiosRequestConfig) => ({
  ...defaultConfig,
  ...config,
  headers: { ...defaultConfig.headers, ...config?.headers },
});

export const createUrlCombiner = (base: string) => (url: string) => `${base}${url}`;

export const combineApiUrl = createUrlCombiner(Config.ApiUrl);
