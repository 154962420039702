import React from 'react';
import { Helmet } from 'react-helmet-async';

import { Box, Text, Image } from 'components/styled';

const InternalError = () => {
  return (
    <>
      <Helmet>
        <title>500 - Internal Error</title>
      </Helmet>
      <Box mx="auto" my="200px" maxWidth="800px" px="20px">
        <Image src="images/500.svg" alt="Internal server error" />
        <Text as="h2" textAlign="center" mt="50px">
          Something went wrong
        </Text>
      </Box>
    </>
  );
};

export default InternalError;
