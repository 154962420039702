import { Context, useContext } from 'react';

const useContextMaybe = <T extends unknown>(context: Context<T>, error?: string) => {
  const value = useContext(context);

  if (typeof value === 'undefined') {
    throw new Error(
      error ?? "You are either missing a provider or trying to load a context outside it's provider"
    );
  }

  return value as NonNullable<typeof value>;
};

export default useContextMaybe;
